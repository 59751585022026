/* global EM */
import _ from 'underscore';
import * as entityActions from '../entities/actions/entities';
import * as fileActions from '../entities/actions/files';

export default class Entity{
    constructor(store, entityTypeDef){
        this.store = store;
        this.dispatch = store.dispatch;  

        this.entityTypeDef = entityTypeDef;
        this.idField = entityTypeDef.idField;
        this.name = entityTypeDef.name;
        this.allowDuplicates = entityTypeDef.allowDuplicates || false;
        this.modelName = (entityTypeDef.modelName || this.name).capitalize();
        this.modelNameSingular = entityTypeDef.modelNameSingular || (this.modelName.slice(-1) === 's' ? this.modelName.slice(0, -1) : this.modelName);

        this.uniqueness = entityTypeDef.uniqueness || ['Name'];
        this.isFileListingTable = entityTypeDef.isFileListingTable?true:false;
        
        if (this.isFileListingTable){
            this.fileType = entityTypeDef.fileTypeClass;
        }

        this.optionList = null;
        this.valueList = null;
        this.idList = null;
        this.keyList = null;
    }

    resetCachedLists(){
        this.optionList = null;
        this.valueList = null;
        this.idList = null;
        this.keyList = null;        
    }

    async load(force, isSilent){
        let state = this.store.getState();
        if (!state.domain)return;
        if (!state[this.name] || force){ 
            await this.dispatch(entityActions.loadDomainEntity(state.domain.DomainId, this, isSilent));
            this.resetCachedLists();
        }        
    }

    async create(newItem, entityId){
        let state = this.store.getState();
        if (!state.domain)return;        
        let created = await this.dispatch(entityActions.createDomainEntity(state.domain.DomainId, this, newItem, entityId));        
        this.resetCachedLists();
        EM.markDomainDirty(state.domain.DomainId, this);
        return created;
    }

    async createBulk(newItems, entityId){
        let state = this.store.getState();
        if (!state.domain)return;        
        let created = await this.dispatch(entityActions.createBulkDomainEntity(state.domain.DomainId, this, newItems, entityId));        
        this.resetCachedLists();
        EM.markDomainDirty(state.domain.DomainId, this);
        return created;
    }    

    async delete(ids){
        let state = this.store.getState();
        if (!state.domain)return;
        let deleted = await this.dispatch(entityActions.deleteDomainEntity(state.domain.DomainId, this, ids)).then(() => {
            let msg = EM.t("util.removalMessage", false, [ids.length, (ids.length === 1?'item':'items'), EM.t(this.name + '.title')]);
            EM.setStatusMessage(msg);
        });                
        this.resetCachedLists();
        EM.markDomainDirty(state.domain.DomainId, this);
        return deleted;
    }

    async update(oldItem, newItem){
        let state = this.store.getState();
        if (!state.domain)return;        
        let updated = await this.dispatch(entityActions.updateDomainEntity(state.domain.DomainId, this, newItem, oldItem));                        
        this.resetCachedLists();
        EM.markDomainDirty(state.domain.DomainId, this);
        return updated;
    }   
    
    async import(items, isSilent, modsOnly){
        let state = this.store.getState();
        if (!state.domain)return;        
        let imported = this.dispatch(entityActions.importDomainEntity(state.domain.DomainId, this, items, isSilent, modsOnly));
        this.resetCachedLists();
        EM.markDomainDirty(state.domain.DomainId, this);
        return imported;
    }

    async audit(itemId){
        let state = this.store.getState();
        if (!state.domain)return;  
        let audit = await this.dispatch(entityActions.auditDomainEntity(state.domain.DomainId, this, itemId));
        return audit.data;
    }    

    async clearAll(){
        if (EM.debugMode){
            let state = this.store.getState();
            if (!state.domain)return;        
            await this.dispatch(entityActions.clearAllDomainEntity(state.domain.DomainId, this));                
            this.resetCachedLists();
            EM.markDomainDirty(state.domain.DomainId, this);
        }
    }    

    makeFileName(id, type){
        return this.name + '-' + id + (type||'');
    }

    async loadFile(id, type, force){
        if (!this.isFileListingTable)return;
        let state = this.store.getState();
        if (!state.domain)return;     
        if (!state.files[this.makeFileName(id, type)] || force){   
            return await this.dispatch(fileActions.loadDomainEntityFile(state.domain.DomainId, this, id));
        }
    }

    async saveFile(id, data){
        if (!this.isFileListingTable)return;
        let state = this.store.getState();
        if (!state.domain)return;     
        return await this.dispatch(fileActions.updateDomainEntityFileBackground(state.domain.DomainId, this, id, data));
    }

    async invalidateFile(id){
        if (!this.isFileListingTable)return;
        let state = this.store.getState();
        if (!state.domain)return;     
        return await this.dispatch(fileActions.invalidateFile(state.domain.DomainId, this, id));
    }

    async loadDefaultItem(){
        let self = this;
        await self.load();
        return this.findDefaultItem();
    }       

    clear(){
        this.resetCachedLists();
        this.dispatch(entityActions.clearDomainEntity(this));
    }

    isLoaded(){
        return this.get() ? true : false;
    }

    get(){
        let state = this.store.getState();
        return state[this.name];
    }

    getFile(id, type){
        let state = this.store.getState();
        return state.files[this.makeFileName(id, type)];
    } 

    asOptionList(labelField, sortByLbl, valueField){
        let state = this.get();
        if (!state)return [];
        if (!this.optionList){
            this.optionList = state.map((row) => {
                let lbl = row[labelField || 'Name' || 'Country' ];
                if(row?.Country){
                    lbl = row['Country' ];
                }
                if (labelField && typeof labelField === 'function'){
                    lbl = labelField(row);
                }
                return { value: row[valueField || this.idField], label: lbl };
            });
            if (sortByLbl){
                this.optionList = _.sortBy(this.optionList, (item) => {
                    return item.label;
                });
            }
        }
        return this.optionList;
    }

    asIndexed(){
        let state = this.get();
        if (!state)return [];
        if (!this.idList){
            this.idList = _.indexBy(state, this.idField.toString());
        }
        return this.idList;
    }
    
    asKeyed(){
        let state = this.get();
        if (!state)return [];
        if (!this.keyList){
            this.keyList = _.indexBy(state, (item) => {
                return this.makeKeyFromItem(item);
            });
        }
        return this.keyList;
    }

    asValueList(labelField){
        let state = this.get();
        if (!state)return [];
        if (!this.valueList){
            this.valueList = {};
            state.forEach((row) => {
                this.valueList[row[labelField || 'Name']] = row[this.idField];
            });
        }
        return this.valueList;
    }
    
    makeKeyFromItem(item){
        let fieldValues = this.uniqueness.map((objKey) => {
            return item[objKey] || '';
        });

        return fieldValues.join('.');
    }

    findItem(item){
        let key = this.makeKeyFromItem(item);
        if (!key){
            console.log('Key could not be created for entity. Check that the uniqueness field is set in entityTypes: ' + this.name);
            return null;
        }
        return this.findByKey(key);
    }

    findByKey(key){
        return this.asKeyed()[key];
    }

    hasDuplicates(){
        let state = this.get();
        if (!state)return false;        
        let dupes = {};        
        for(var i = 0; i < state.length; i ++){
            let item = state[i];            
            if (typeof item.Active !== 'undefined' && item.Active === false)continue;
            let key = this.makeKeyFromItem(item);
            if (dupes[key]){            
                EM.warn('First duplicate found: ', item);
                return true;
            }else{
                dupes[key] = true;
            }
        };
        return false;
    }

    findDuplicates(){
        let state = this.get();
        if (!state)return false;        
        let keys = {};      
        let dupes = {};  
        for(var i = 0; i < state.length; i ++){
            let item = state[i];
            if (typeof item.Active !== 'undefined' && item.Active === false)continue;
            let key = this.makeKeyFromItem(item);
            if (keys[key]){
                dupes[item[this.idField]] = true;
            }
            keys[key] = true;
        };
        return dupes;
    }

    findDefaultItem(){
        let self = this;
        let currentSet = self.get();
        if (!currentSet)return null;

        let defaultItem = currentSet.find(item => { return item.IsDefault });
        if (!defaultItem){
            console.warn('No default. Returning the newest item instead.');
            defaultItem = currentSet[currentSet.length - 1];
        }

        return defaultItem;
    } 

    lookupValue(id, fld){
        let found = this.asIndexed()[id];
        return found ? found[fld || 'Name'] || found[fld || 'Country'] : '';
    }

    lookupId(value, fld){        
        return this.asValueList(fld)[value];
    }

    byId(id){
        return this.asIndexed()[id];
    }

    //Translation helpers
    t(key, exact){
        return EM.t(this.name + '.' + key, exact);
    }

    columns(key, exact){        
        return EM.t(this.name + '.columns.' + key, exact);
    } 
    
    isOverridden(){
        return EM.isOverridden(this);
    }
}