/* global EM */
import React, { Component } from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label, FormFeedback, CustomInput, FormText, Alert } from 'reactstrap';
import PanelModal from '../PanelModal';
import { Type } from 'react-bootstrap-table2-editor';
import { Validators, Validate } from '../../util/EntityValidators';
import WorkItemSelector from '../WorkItemSelector';
import CsvFileViewer from '../CsvFileViewer';
import File from '../../util/File';
import _ from 'underscore';
import ExpressionLocal from '../../util/ExpressionLocal';
import PercentageEditField from '../PercentageEditField';
import AssignmentStatusEditField from '../Staffing/AssignmentStatusEditField';
import MonthPickerControlled from '../MonthPickerControlled';
import PipelineRunner from '../../entities/pipelines/PipelineRunner';
import Spinner from '../Spinner';

class EntityCreator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            currentFile: null,
            fileErrors: [], 
            parsingFile: false           
        };
        
        this.onClose = this.onClose.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.validate = this.validate.bind(this);
        this.parseFile = this.parseFile.bind(this);

        this.entityTitle = this.props.entity.t('title');            
        this.ctrlRefs = {};
    }

    clearState(withErrors){
        this.setState({ errors: {}, currentFile: null, fileErrors: withErrors||[], parsingFile: false });              
    }    

    onClose(){
        this.props.onClose();
        this.clearState();
    }

    async parseFile(event){
        var input = document.getElementById('uploadFile');

        let csvContents = null;
        try {
            if (this.props.entity.fileType.allowsXml){
                csvContents = await File.readCsvOrXml(input, this.props.entity.fileType.readXml);
            }else{
                csvContents = await File.readCSV(input);
            }
        }catch(e){
            console.log(e);
            this.clearState([[ '', e.message ]]);
            return;
        }

        if (!csvContents){
            this.setState({ parsingFile: false });
            return;
        }

        try{
            csvContents = await PipelineRunner.input(this.props.entity, csvContents);
        }catch(e){
            console.warn(e);
            this.clearState([[ '', 'Pipeline Error: ' + e.message ]]);
            return;
        }

        let fileErrors = await this.props.entity.fileType.validate(csvContents);
        this.setState({ currentFile: csvContents, fileErrors: fileErrors||[], parsingFile: false });                   
    }    

    async onCreate(){
        let self = this;
        let columnIndex = _.indexBy(this.props.columns, 'dataField');

        let errs = [];
        let outputObject = {};
        Object.keys(this.ctrlRefs).forEach((key) => {
            let ctrl = this.ctrlRefs[key].current;            
            if (!ctrl)ctrl = this.ctrlRefs[key];            
            let value = null;
            value = ctrl.props ? (ctrl.props.value ? ctrl.props.value.value : null) : ctrl.value;            
            if (!value && ctrl.getValue)value = ctrl.getValue();   
            if (value === "true")value = true;
            if (value === "false")value = false;

            let valResult = this.validate(value, columnIndex[key]);
            if (valResult)errs.push(valResult);
            if (_.isString(value)){
                value = value.trim();
            }
            outputObject[key] = (value === "" ? null : value);
        });

        if (errs.length > 0)return;

        if (this.props.entity.isFileListingTable){
            var fileCtrl = document.getElementById('uploadFile');
            let valResult = await this.validate(fileCtrl.value, { dataField: 'File', validators: [ Validators.required ] });
            if (valResult)errs.push(valResult);
            if (errs.length > 0)return;
            
            outputObject['File'] = this.props.entity.fileType.preSaveTransform(this.state.currentFile);
        }        

        if (self.props.onCreate){
            self.props.onCreate(outputObject);                
        }else{
            this.props.entity.create(outputObject);
        }

        this.props.onClose();        
        this.clearState();
    }

    getCtrlRef(column, ref){
        return this.ctrlRefs[column.dataField] = ref || React.createRef();
    }

    validate(value, column){
        let currentValue = value;
        if (column.validators){
            let valResult = Validate(currentValue, column.validators, (column.getValidationContext ? column.getValidationContext() : null), 'creation');            
            let errs = {};
            if (valResult !== null){
                errs = Object.assign(this.state.errors, { [column.dataField]: valResult });
            }else{
                errs = Object.assign({}, this.state.errors);                
                delete errs[column.dataField];
            }
            this.setState({ errors: errs });
            return valResult;
        }        
        return null;
    }

    getSelect(column, options){        
        let valMsg = this.state.errors[column.dataField];
        return (<Input key="ctrl" type="select" innerRef={this.getCtrlRef(column)} onChange={(event) => this.validate(event.target.value, column)} invalid={valMsg?true:false}>
            { !column.allowNulls ? <option key="default"></option> : null }
            { _.map(options ? options : column.editor.options,(option, index) => {                
                return (<option value={option.value} key={index}>{option.label}</option>);
            })}
        </Input>);
    }

    getTextarea(column, options){        
        let valMsg = this.state.errors[column.dataField];
        return (<Input key="ctrl" type="textarea" innerRef={this.getCtrlRef(column)} onChange={(event) => this.validate(event.target.value, column)} invalid={valMsg?true:false} />);
    }    

    getControl(column){
        let valMsg = this.state.errors[column.dataField];
        let output = [];
        if (column.editor && !column.asActiveLocal){
            if (column.editor.type === Type.SELECT){
                output.push(this.getSelect(column));
            }else if (column.editor.type === Type.TEXTAREA){
                output.push(this.getTextarea(column));
            }
        }else if (column.asActive===true){
            output.push(
                <Input key="ctrl" type="select" innerRef={this.getCtrlRef(column)} onChange={(event) => this.validate(event.target.value, column)} invalid={valMsg?true:false}>
                    <option value={true} key="yes">Yes</option>
                    <option value={false} key="no">No</option>
                </Input>
            )                       
        }
        else if (column.asActiveLocal===true){
            output.push(
                <Input key="ctrl" type="select" innerRef={this.getCtrlRef(column)} onChange={(event) => this.validate(event.target.value, column)} invalid={valMsg?true:false}>
                    <option value={false} key="no">No</option>
                    <option value={true} key="yes">Yes</option>
                </Input>
            )                       
        }else if (column.asWorkItem===true){
            output.push(
                <WorkItemSelector value={null} key="wi" innerRef={ref => this.getCtrlRef(column, ref)} onChange={(selected, event) => this.validate(selected?selected.value:null, column)} invalid={valMsg?true:false} />
            )  
        }else if (column.asAssignmentStatus===true){
            output.push(<AssignmentStatusEditField key="ctrl" ref={this.getCtrlRef(column)} onChange={(event) => this.validate(event.target.value, column)} invalid={valMsg?true:false} />)
        }else if(column.asDate===true){
            //output.push(<Input key="ctrl" type="date"  onChange={(event) => this.validate(event.target.value, column)} invalid={valMsg?true:false} />);
            output.push(
                <MonthPickerControlled
                    key="ctrl"
                    ref={this.getCtrlRef(column)}
                    onChange={(date) => {
                        this.validate(date.toISO(), column);
                    }}
                />
            );
        }else if(column.asPercentage===true){
            output.push(<PercentageEditField key="ctrl" ref={this.getCtrlRef(column)} onChange={(event) => this.validate(event.target.value, column)} invalid={valMsg?true:false} />);
        }else if (column.asExpression===true){               
            output.push(
                <div key="exp-msg" className="alert alert-secondary">
                    {EM.t('util.table.expressionMessage')}
                    <Input style={{ display: 'none' }} key="ctrl" type="text" innerRef={this.getCtrlRef(column)} 
                    value={ExpressionLocal.getDefaultExpressionStr()}
                    onChange={(event) => this.validate(event.target.value, column)} invalid={valMsg?true:false} disabled />
                </div>
            )                       
        }else if (column.asReferenceTable===true){               
            output.push(
                <div key="exp-msg" className="alert alert-secondary">
                    {EM.t('util.table.referenceTablesMessage')}
                    <Input style={{ display: 'none' }} key="ctrl" type="text" innerRef={this.getCtrlRef(column)} 
                    value={''}
                    onChange={(event) => this.validate(event.target.value, column)} invalid={valMsg?true:false} disabled />
                </div>
            )                       
        }else{
            output.push(<Input key="ctrl" type="text" autoComplete="off" list="autocompleteOff" aria-autocomplete="none" innerRef={this.getCtrlRef(column)} onChange={(event) => this.validate(event.target.value, column)} invalid={valMsg?true:false} />);
        }        

        if (column.description){
            output.push(<FormText key="description">{column.description}</FormText>);
        }

        if (valMsg){
            output.push(<FormFeedback key="msg">{valMsg}</FormFeedback>);
        }

        return output;
    }

    render() {
        let canCreate = Object.keys(this.state.errors).length === 0 && this.state.fileErrors.length === 0;
        if (this.props.entity.isFileListingTable && !this.state.currentFile){
            canCreate = false;
        }
        return (
            <PanelModal fade={false} isOpen={this.props.isOpen} toggle={this.onClose} className={'panel panel-full ' + this.props.className} key="create-modal">
                <ModalHeader toggle={this.onClose}>{EM.t('util.table.createTitle', false, [ this.entityTitle ])}</ModalHeader>
                <ModalBody>
                    <Form className="form-create">
                        {this.props.columns.map((column) => {
                            if (column.isDummyField || column.isReference || column.isDateReference || column.creatable === false)return null;
                            if (column.customInsertField) {                                                                                          
                                return column.customInsertField(column,this);                            
                            } else {
                                return (
                                    <FormGroup key={column.dataField}>
                                        <Label>{column.text}:</Label>
                                        {this.getControl(column)}
                                    </FormGroup>
                                );
                            }
                        })}
                        {this.props.entity.isFileListingTable ?
                            <div>
                                <FormGroup className={this.state.errors['File']?'custom is-invalid':''}>
                                    <Label for="uploadFile">{EM.t('util.table.selectFileLabel')}</Label>
                                    <CustomInput type="file" name="file" id="uploadFile" accept={".csv" + (this.props.entity.fileType.allowsXml ? ',.xml' : '')} onClick={event => event.target.value = null} onChange={async (event) => {
                                        let fileName = event.target.value.replace("C:\\fakepath\\", "");
                                        document.getElementsByClassName('custom-file-label')[0].innerText = fileName;
                                        this.validate(event.target.value, { dataField: 'File', validators: [ Validators.required ] });
                                        this.setState({ parsingFile: true });
                                        await this.parseFile();                      
                                    }} label={EM.t('util.table.selectFilePlaceholder')} />
                                    <FormFeedback>{this.state.errors['File']}</FormFeedback>
                                    <FormText color="muted">
                                        {this.props.entity.fileType.allowsXml ? EM.t('util.table.selectFileInstructionsWithXml') : EM.t('util.table.selectFileInstructions')}
                                    </FormText>                                    
                                </FormGroup> 
                                {this.state.parsingFile ? 
                                    <Spinner text="Loading. This may take some time..." classes="small" />
                                : null }
                                {this.state.fileErrors.length <= 0 && this.state.currentFile !== null ?
                                    <Alert color="success">{EM.t('util.table.createAllowed')}</Alert>
                                : null }                                 
                                {this.state.fileErrors.length > 0 ?       
                                    <Alert color="danger">{EM.t('util.table.createDisallowed')}</Alert>               
                                : null } 
                                {this.state.fileErrors.length > 0 ?       
                                    <ul className="validation-list">                  
                                        {this.state.fileErrors.slice(0, 20).map((error, index) => { 
                                            let lr = error[0] ? error[0].split(':') : null;
                                            return <li className="text-danger" key={index}>
                                                { lr ? 
                                                    <span>Line {parseInt(lr[0]) + 1}, Column {parseInt(lr[1]) + 1}:&nbsp;</span>
                                                : null }
                                                {error[1]}
                                            </li>
                                        })}
                                        {this.state.fileErrors.length > 20 ? 
                                            <li className="text-danger">{EM.t('util.table.moreErrors', null , [this.state.fileErrors.length - 20])}.</li>
                                        : null}
                                    </ul>
                                : null }                                                                                                          
                                <CsvFileViewer data={this.state.currentFile} errors={this.state.fileErrors} />                                 
                            </div>
                        : null }  
                    </Form>                    
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.onClose}>{EM.t('util.cancel')}</Button>
                    <Button color="primary" disabled={!canCreate} onClick={this.onCreate}>{EM.t('util.table.createButton')}</Button>
                </ModalFooter>
            </PanelModal>
        );
    }
}

export default EntityCreator;
