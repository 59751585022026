/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import BudgetsTabs from '../components/BudgetsTabs';
import { Validators } from '../util/EntityValidators';


class InflationFactor extends Component {
    constructor(props){
        super(props);
        EM.inflationFactors.load();
        EM.countryCurrencies.load();
    }
    render(){
      let data = null;
      let columns = null;
      const entity = EM.inflationFactors;
      if(EM.allLoaded(entity)){
        data = entity.get();
        columns = [
            {
                dataField: "InflationFactorId",
                text: "Country",
                validators: [Validators.required],
                fromEntity: EM.inflationFactors,
            },
            {
                dataField: "InflationFactor",
                text: "Inflation Factor",
                validators: [Validators.required],
                filterOptions: {
                  text: true,
                },
            },
        ];
    }
    return(
        <div key="contents" className="page container-fluid">
             <BudgetsTabs {...this.props}/>
             <EntityTable entity={entity} data={data} columns={columns} className="table-overflow-container tabs"/>
        </div>
    )
  }
}

export default InflationFactor;