/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import BudgetsTabs from '../components/BudgetsTabs';
import { Validators } from '../util/EntityValidators';
import { Type } from 'react-bootstrap-table2-editor';

class CurrencyConversions extends Component {
    constructor(props) {
        super(props);
        EM.currencyConversions.load()
        EM.currencyCodes.load();
    }

    render() {
        let data = null;
        let columns = null;
        const entity =  EM.currencyConversions;  
           
     
        if (EM.allLoaded(entity,EM.currencyCodes)){    
            data = entity.get();
            let countryCurrencyCode = EM.currencyCodes.get();
            let options = countryCurrencyCode.map((item) => {
              return {value:item.CurrencyCodeId,label:item.Code}
            })
            let currencyCodeLookup = (cell,row)=>{
                return EM.currencyCodes.lookupValue(Number(row.CurrencyCodeId),'Code'); 
            }
            let filterCurrencyCodeLookup = (cell,row)=>{
              return EM.currencyCodes.lookupValue(Number(row.CurrencyCodeId),'CurrencyCodeId'); 
          }
            columns = [
                {
                    dataField: 'CurrencyCodeId',
                    text: (entity.columns('CurrencyCode')).split('.').pop(),
                    validators: [Validators.required],
                    formatter: currencyCodeLookup,
                    sortValue: currencyCodeLookup,
                    filterValue: filterCurrencyCodeLookup,
                    editor : {
                      type: Type.SELECT,
                      options: options
                    },
                    filterOptions: {
                      select: true,
                      options: options,
                  },
                },
                {
                    dataField: 'ConversionRate',
                    text: (entity.columns('ConversionRate')).split('.').pop(),
                    validators: [Validators.required],
                    filterOptions: {
                        text: true,
                    },
                },
             ];
        }

        return (
            <div key="contents" className="page container-fluid">
                <BudgetsTabs {...this.props} />
                <EntityTable
                    entity={entity} 
                    data={data}
                    columns={columns}
                    className="table-overflow-container tabs"
                />
            </div>
        );
    }
}

export default CurrencyConversions;
