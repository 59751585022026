/* global EM */
import React, { Component } from 'react';
import { Label, FormGroup, FormText } from 'reactstrap';
import Select from 'react-select';

export default class SettingsDropdownInput extends Component {
    constructor(props){
        super(props);
        EM.currencyCodes.load();
        this.settingObj = EM.settings.findByKey(props.setting);       
        let valueStr = null; 
        let valueArr = [];
        if (this.settingObj) valueStr = this.settingObj.Value;        
        if (valueStr)valueArr = valueStr.split(',');

        let valueObjects = [];
        let options = props.options ? this.props.options.map((item) => {
            let output = { value: item.value || item, label: item.label || item };
            if (valueArr.indexOf(output.value) > -1)valueObjects.push(output);
            return output;
        }) : [];
        
        if(props.setting === "BaseCurrency") {
          valueObjects.push({value: valueArr[0],label: valueArr[0]})
        }
        this.state = {
            inputValue: '',
            value: valueObjects,
            options: options
        };
    }
    
    handleChange = (value, actionMeta) => {    
        this.saveSetting(value);
        this.setState({ value });
    };

    saveSetting(valueObjects){
        let valueStr = '';

        if (this.props.isMulti){
            let valueBits = valueObjects?valueObjects.map(item => { return item.value }):[];
            valueStr = valueBits.join(',');
        }else{
            valueStr = valueObjects.value;
        }
        
        if (this.settingObj){
            let newObj = Object.assign({}, this.settingObj, { Value: valueStr });
            EM.settings.update(this.settingObj, newObj);
        }else{
            EM.settings.create({
                Key: this.props.setting,
                Value: valueStr
            }).then((newItem) => {
                this.settingObj = newItem.data;
            });        
        }
    }
    
    render() {
        const { setting, classes, isMulti } = this.props;
        let keyTbase = 'settings.generalSettings.' + setting.toLowerCase() + '.';      
        let currencyCodes = null;
        let currencyCodeOptions = null;
        let entity = EM.currencyCodes;
        if (EM.allLoaded(entity) && setting === "BaseCurrency"){
          currencyCodes = entity.get();
          currencyCodeOptions = currencyCodes.map((item) => {
            return {value:item.Code,label:item.Code}
          })
        }

        return (
            <FormGroup className={classes}>
                <Label className='labelText'>{EM.t(keyTbase + 'label')}:</Label>
                <FormText className='labelDescription'>{EM.t(keyTbase + 'description')}</FormText>
                {isMulti ? 
                    <Select
                        isClearable={false}
                        isMulti
                        className="multi-select wide"
                        classNamePrefix="multi-select"
                        onChange={this.handleChange}
                        placeholder={EM.t('util.filtering.typehere')}
                        value={this.state.value}
                        options={this.state.options}
                    />
                : 
                    <Select
                        isClearable={false}
                        className="single-select wide"
                        classNamePrefix="single-select"
                        onChange={this.handleChange}
                        placeholder={EM.t('util.filtering.placeholderDefault')}
                        value={this.state.value}
                        options={setting === "BaseCurrency" ? currencyCodeOptions : this.state.options}
                    />
                }
            </FormGroup>
        );
    }
};