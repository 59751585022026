/* global EM */
import React, { Component } from 'react';
import PageTitle from '../components/PageTitle';
import StaffingWorkItemPreferences from '../entities/preferences/StaffingWorkItemPreferences';
import StaffingEmployeePreferences from '../entities/preferences/StaffingEmployeePreferences';
import ProjectionsManager from '../entities/ProjectionsManager';
import ReportConfiguration from '../entities/reports/ReportConfiguration';
import PublishOptionsDialog from '../components/PublishOptionsDialog';
import Routes from '../app/Routes';
import Dates from '../util/Dates';
import _ from 'underscore';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ColumnMapper from '../components/ColumnMapper';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Label } from 'reactstrap';
import { Workbook } from '../util/Workbook';
import ReportDetailsDialog from '../components/ReportDetailsDialog';

export default class StaffingReport extends Component {
    constructor(props) {
        super(props, StaffingWorkItemPreferences);

        this.state = {
            config: null,
            preferences: null,
            primary: null,
            secondary: null,
            tertiary: null,
            selectedSeries: null,
            publishModalOpen: false
        }
        this.defaultRangeInYears = 2;
        this._isMounted = false;
        this.PM = new ProjectionsManager(this);
        this.demandGraphRef = React.createRef();
        this.onPrint = this.onPrint.bind(this);
        this.onExport = this.onExport.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onPublish = this.onPublish.bind(this);
        this.onPublishModalClose = this.onPublishModalClose.bind(this);
        this.processAssignments = this.processAssignments.bind(this);
        this.groupAndFilterData = this.groupAndFilterData.bind(this);
        this.processSingleAssignment = this.processSingleAssignment.bind(this);
        this.applyEmployeeFilters = this.applyEmployeeFilters.bind(this);
        this.toolkitRef = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;

        let self = this;
        EM.loadEntities([EM.settings, EM.configurations, EM.configurationTypeLookup, EM.activities, EM.roles, EM.organizations, EM.departments, EM.employees, EM.assignments, EM.actuals, EM.referenceTables]).then(() => {
            self.onLoadInitial();
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getPreferenceType() {
        let params = Routes.parseQuery();
        if (params.type) {
            if (params.type === 'employee') {
                this.reportType = "employee";
            } else {
                this.reportType = "workitem";
            }
        } else {
            let storedConfig = EM.configurations.byId(params.configuration);
            if (!storedConfig) {
                throw new Error('Configuration not found', params.configuration);
            }
            let tmp = JSON.parse(storedConfig.Value);
            if (tmp.options.reportType) {
                if (tmp.options.reportType === "employee") {
                    this.reportType = "employee";
                } else {
                    this.reportType = "workitem";
                }
            } else {
                if (storedConfig.Value.indexOf('groupedByEmployee') > -1 || storedConfig.Value.indexOf('groupByCostCenter') > -1) {
                    this.reportType = "employee";
                } else {
                    this.reportType = "workitem";
                }
            }
        }

        if (this.reportType === "employee") {
            return StaffingEmployeePreferences;
        } else {
            return StaffingWorkItemPreferences;
        }
    }

    onLoadInitial() {
        let self = this;

        let config = ReportConfiguration.fromQueryString(this.getPreferenceType());
        if (!config) {
            console.log('Can\'t infer report type.');
            return;
        }
        self.setState({ config: config, preferences: config.baseConfig });

        EM.schedules.loadDefaultItem().then((defaultSchedule) => {
            if (!defaultSchedule) return;
            self.defaultSchedule = defaultSchedule;
            EM.schedules.loadFile(defaultSchedule.ScheduleId).then(() => {
                let fileContents = EM.schedules.getFile(defaultSchedule.ScheduleId);
                if (fileContents) {
                    self.setState({ defaultScheduleContent: fileContents });
                }
            });

            self.PM.loadDefaultProjections().then(projectionsFile => {
                if (projectionsFile) {
                    self.projectionsFile = projectionsFile;
                    self.projectionsFile.processDateRanges();
                    self.filterIndexes = self.projectionsFile.filterIndexes;
                }
                self.processAssignments(EM.assignments.get());
                self.groupAndFilterData(self.state.preferences);
            });
        });
    }

    groupAndFilterData(nextPreferences) {
        let newState = {
            preferences: nextPreferences,
            selectedItem: null
        };

        //let grouping = nextPreferences.grouping || '';
        if (this.projectionsFile) {
            newState.groups = this.projectionsFile.groupAndFilterProjections(nextPreferences);
        }
        let range = Dates.getMonthRangeFromMonthYearStrs(nextPreferences.begin, nextPreferences.end, this.defaultRangeInYears);
        newState.range = Dates.getArrayOfMonths(range[0], range[1], true);
        this.setState(newState);
    }

    processAssignments(assignmentsSource) {
        let assignments = [];
        let employees = EM.employees.get();
        let self = this;

        let filteredAssignments = assignmentsSource;

        if (this.reportType === 'employee') {
            employees = this.applyEmployeeFilters(employees);
            let employeeIds = _.pluck(employees, "EmployeeId");
            filteredAssignments = _.filter(filteredAssignments, function (assignment) {
                return _.contains(employeeIds, assignment.EmployeeId);
            });
        } else {
            let workItemFilter = [];
            let filteredProjections = this.projectionsFile.groupAndFilterProjections(self.state.preferences);
            if (filteredProjections) {
                Object.values(filteredProjections).forEach(group => {
                    Array.prototype.push.apply(workItemFilter, Object.keys(group));
                });
            }

            workItemFilter = _.map(workItemFilter, function (w) { return w.trim(); });
            filteredAssignments = filteredAssignments.filter(function (assignment) {
                let response = workItemFilter.includes(assignment.WorkItemName.trim());
                return response;
            });
        }

        filteredAssignments.forEach((aa) => {
            let assignment = this.processSingleAssignment(aa);
            assignments.push(assignment);
        });

        let assignmentGroups = _.groupBy(Object.values(filteredAssignments), 'WorkItemName');
        this.setState({ assignments, assignmentGroups, employees });
    }

    processSingleAssignment(assignmentFromSource) {
        let assignment = Object.assign({}, assignmentFromSource);
        assignment.BeginDateObj = Dates.fromISO(assignment.BeginDate);
        assignment.BeginDateStr = Dates.toMonthYearStrShort(assignment.BeginDateObj);
        assignment.EndDateObj = Dates.fromISO(assignment.EndDate);
        assignment.EndDateStr = Dates.toMonthYearStrShort(assignment.EndDateObj);

        let range = Dates.getArrayOfMonths(assignment.BeginDateObj, assignment.EndDateObj, true);
        assignment.range = range;
        assignment.dateKeys = [];

        assignment.range.dates.forEach((month, monthIndex) => {
            assignment.dateKeys.push(range.beginIndex + monthIndex);
        });

        return assignment;
    }

    onPrint() {
        window.setTimeout(() => {
            window.print();
        }, 0);
    }

    onExport() {
        console.log(this.toolkitRef.current);
    }

    onDelete() {
        let self = this;
        self.onPublishModalClose();
        EM.configurations.delete([this.state.config.storedConfiguration.ConfigurationId]).then(() => {
            let reportAddress = Routes.compose(Routes.client.dashboard, { DomainName: this.props.domain.Name });
            this.props.history.push(reportAddress);
        });
    }

    onPublish(options) {
        let self = this;

        let type = EM.configurationTypeLookup.findByKey('StaffingReport');
        if (!options) return;
        if (this.state.config.storedConfiguration) {
            let newConfig = Object.assign(this.state.config.storedConfiguration, options);
            EM.configurations.update(this.state.config.storedConfiguration, newConfig).then((newObj) => {
                let config = ReportConfiguration.fromQueryString(this.getPreferenceType());
                self.setState({ config: config });
                self.onPublishModalClose();
            });
        } else {
            let newConfig = Object.assign({ Value: this.state.config.asJSON(), ConfigurationTypeLookupId: type.ConfigurationTypeLookupId }, options);
            EM.configurations.create(newConfig).then((newObj) => {
                let reportAddress = Routes.compose(Routes.client.dashboard, { DomainName: this.props.domain.Name });
                this.props.history.push(reportAddress);
            });
        }
    }

    onPublishModalClose() {
        this.setState({ publishModalOpen: false });
    }

    onLegendItemClicked(seriesClicked, isFocusMode) {
        if (this.demandGraphRef.current) this.demandGraphRef.current.onLegendItemClicked(seriesClicked, isFocusMode);
        this.setState({ selectedSeries: seriesClicked });
    }

    onLegendItemsToggled(visible) {
        if (this.demandGraphRef.current) this.demandGraphRef.current.onLegendItemsToggled(visible);
    }

    getAssignmentsInMonth(monthIndex, assignments) {
        let result = _.filter([...assignments], function (assignment) {
            return _.contains(assignment.dateKeys, monthIndex);
        });
        return result;
    }

    getProjectionsInMonth(monthIndex, projections, roleId) {
        let self = this;
        if (projections && projections.length > 0)
            return _.filter([...projections], function (projection) {
                return _.contains(projection[self.filterIndexes.dateKeys], monthIndex) && (!roleId || projection[self.filterIndexes.roleId] === roleId);
            });

        else return [];
    }

    groupByRowFormatter(cell, row) {
        if (row.isGroupedByRow)
            return (<b>{cell}</b>);
        else
            return cell;
    }

    getListingTypeReportData(employees, projectionGroups) {
        let self = this;
        let data = [];
        let assignmentGroups = _.groupBy(self.state.assignments, function (assignment) { return assignment.WorkItemName + "^" + assignment.EmployeeId; });

        _.each(assignmentGroups, function (assignmentGroup) {
            _.each(self.state.range.dates, function (month, monthIndex) {
                let currentMonth = self.state.range.beginIndex + monthIndex;
                let firstAssignment = assignmentGroup[0];
                let monthAssignments = self.getAssignmentsInMonth(currentMonth, assignmentGroup);
                let total = 0;
                _.each(monthAssignments, function (m) { total += m.Value; });

                let projections = projectionGroups[firstAssignment.WorkItemName.trim()];
                let projectionInMonth = self.getProjectionsInMonth(currentMonth, projections);
                let projectionsTotal = 0;
                _.each(projectionInMonth, function (m) { projectionsTotal += m[self.filterIndexes.value]; });
                if (total > 0 || projectionsTotal > 0)
                    data.push({
                        Study: firstAssignment.WorkItemName,
                        Date: Dates.toMonthYearStr(month),
                        Assignment: total.toFixed(2).replace(/[.,]00$/, ""),
                        DemandProjection: projectionsTotal.toFixed(2).replace(/[.,]00$/, "")
                    });

            });
        });
        return data;
    }

    getGroupedListingTypeReportData(employees, projectionGroups) {
        let self = this;
        let data = [];
        let assignmentGroups = _.groupBy(self.state.assignments, function (assignment) { return assignment.WorkItemName.trim() });
        let sortedAssignementGroups = _.sortBy(_.keys(assignmentGroups));

        let departments = EM.departments.get();
        let organizations = EM.organizations.get();
        let roles = EM.roles.get();

        _.each(sortedAssignementGroups, function (group) {
            let assignments = _.sortBy(assignmentGroups[group], (assignment) => { return assignment.ActivityName + assignment.EmployeeId });
            let rows = [];
            _.each(assignments, function (assignment, cnt) {
                let employee = _.findWhere(employees, { EmployeeId: assignment.EmployeeId });
                if (!employee)return;
                let role = _.findWhere(roles, { RoleId: employee.RoleId });
                if (!role)return;
                let department = _.findWhere(departments, { DepartmentId: role.DepartmentId });
                let organization = _.findWhere(organizations, { OrganizationId: department.OrganizationId });
                
                let record = {
                    id: group + '-' + cnt,
                    Study: assignment.WorkItemName,
                    Activity: assignment.ActivityName,
                    Organization: organization.Name,
                    Department: department.Name,
                    Role: role.Name,
                    Label:assignment.Label,
                    CostCenter: employee.CostCenter,
                    Contractor: employee.Contractor === true ? 'Yes' : 'No',
                    Employee: employee.FirstName + " " + employee.LastName,
                };

                _.each(self.state.range.dates, function (month, monthIndex) {
                    record["_" + Dates.toMonthYearStrShort(month)] = _.contains(assignment.range.dates, month) ? (assignment.Value * 100).toFixed(0) + '%' : "0";
                });

                //let projections = projectionGroups[assignment.WorkItemName.trim()];
                //let projection = projections ? _.filter([...projections], function (projection) { return projection[self.filterIndexes.roleId] === role.RoleId; })[0] : null;
                //record.Program = projection ? projection[self.filterIndexes.Program] : "";
                //record["Therapeutic Area"] = projection ? projection[self.filterIndexes["Therapeutic Area"]] : "";

                rows.push(record);
            });
            if (rows.length > 0) {
                var firstRecord = rows[0];
                let totalsRecord = {
                    id: group + 'total',
                    Study: firstRecord.Study ? firstRecord.Study : "",
                    Organization: "",
                    Department: "",
                    Role: "",
                    Label:"",
                    CostCenter: "",
                    Contractor: "",
                    Employee: "Total",
                    isGroupedByRow: true
                };

                _.each(self.state.range.dates, function (month, monthIndex) {
                    let monthName = "_" + Dates.toMonthYearStrShort(month);
                    let dateValues = _.pluck(rows, monthName);
                    let total = 0;
                    total = _.reduce(dateValues, function (memo, num) { 
                        return memo + (parseFloat(num) || 0); 
                    }, 0);
                    totalsRecord[monthName] = _.isNaN(total) ? "0" : total.toFixed(0) + '%';

                });
                rows.push(totalsRecord);
            }
            data = [...data, ...rows];
        });

        return data;
    }

    getEmployeeTypeReportData(employees, projectionGroups) {
        let self = this;
        let data = [];
        let assignmentGroups = _.groupBy(self.state.assignments, 'EmployeeId');
        let sortedAssignementGroups = _.sortBy(_.keys(assignmentGroups));

        let departments = EM.departments.get();
        let organizations = EM.organizations.get();
        let roles = EM.roles.get();

        _.each(sortedAssignementGroups, function (group) {
            let assignments = _.sortBy(assignmentGroups[group], 'WorkItemName');
            let rows = [];
            _.each(assignments, function (assignment, cnt) {
                let employee = _.findWhere(employees, { EmployeeId: assignment.EmployeeId });
                if (!employee) return;
                let role = _.findWhere(roles, { RoleId: employee.RoleId });
                if (!role) return;
                let department = _.findWhere(departments, { DepartmentId: role.DepartmentId });
                let organization = _.findWhere(organizations, { OrganizationId: department.OrganizationId });
                let name = employee.FirstName + " " + employee.LastName;
                let record = {
                    id: group + '-' + cnt,
                    Study: assignment.WorkItemName,
                    Activity: assignment.ActivityName,
                    Organization: organization.Name,
                    Department: department.Name,
                    Role: role.Name,
                    Label:assignment.Label,
                    CostCenter: employee.CostCenter,
                    Contractor: employee.Contractor === true ? 'Yes' : 'No',
                    Employee: name
                };

                let rowTotal = 0;
                _.each(self.state.range.dates, function (month, monthIndex) {
                    let val = _.contains(assignment.range.dates, month) ? assignment.Value * 100 : 0;
                    record["_" + Dates.toMonthYearStrShort(month)] = val > 0 ? val.toFixed(0) + '%' : "";
                    rowTotal = rowTotal + val;
                });

                if (rowTotal > 0) rows.push(record);
            });

            if (rows.length > 0) {
                let record = rows[0];
                let totalsRecord = {
                    id: group + 'total',
                    Study: "",
                    Organization: record.Organization ? record.Organization : "",
                    Department: record.Department ? record.Department : "",
                    Role: record.Role ? record.Role : "",
                    Label:"",
                    CostCenter: "",
                    Contractor: "",
                    Employee: record.Employee ? record.Employee : "",
                    Activity: "Total",
                    isGroupedByRow: true
                };

                _.each(self.state.range.dates, function (month, monthIndex) {
                    let monthName = "_" + Dates.toMonthYearStrShort(month);
                    let dateValues = _.pluck(rows, monthName);
                    let total = 0;
                    total = _.reduce(dateValues, function (memo, num) { 
                        return memo + (parseFloat(num) || 0); 
                    }, 0);
                    totalsRecord[monthName] = _.isNaN(total) ? "" : (total > 0 ? total.toFixed(0) + '%' : "");

                });
                rows.push(totalsRecord);
            }
            data = [...data, ...rows];
        });

        return data;
    }

    getCostCenterTypeReportData(employees, projectionGroups) {
        let self = this;
        let data = [];

        let costCenterAssignments = _.map(self.state.assignments, function (assignment) {
            let employee = _.findWhere(employees, { EmployeeId: assignment.EmployeeId });
            return Object.assign({}, _.clone(assignment), { CostCenter: employee.CostCenter });
        });

        let assignmentGroups = _.groupBy(costCenterAssignments, 'CostCenter');
        let sortedAssignementGroups = _.sortBy(_.keys(assignmentGroups));

        let departments = EM.departments.get();
        let organizations = EM.organizations.get();
        let roles = EM.roles.get();

        _.each(sortedAssignementGroups, function (group) {
            let assignments = _.sortBy(assignmentGroups[group], 'WorkItemName');
            let rows = [];
            _.each(assignments, function (assignment, cnt) {
                let employee = _.findWhere(employees, { EmployeeId: assignment.EmployeeId });
                if (!employee) return;
                let role = _.findWhere(roles, { RoleId: employee.RoleId });
                if (!role) return;
                let department = _.findWhere(departments, { DepartmentId: role.DepartmentId });
                let organization = _.findWhere(organizations, { OrganizationId: department.OrganizationId });
                let name = employee.FirstName + " " + employee.LastName;
                let record = {
                    id: group + '-' + cnt,
                    Study: assignment.WorkItemName,
                    Activity: assignment.ActivityName,
                    Organization: organization.Name,
                    Department: department.Name,
                    Role: role.Name,
                    Label:assignment.Label,
                    CostCenter: employee.CostCenter,
                    Contractor: employee.Contractor === true ? 'Yes' : 'No',
                    Employee: name
                };

                let rowTotal = 0;
                _.each(self.state.range.dates, function (month, monthIndex) {
                    let val = _.contains(assignment.range.dates, month) ? assignment.Value * 100 : 0;
                    record["_" + Dates.toMonthYearStrShort(month)] = val > 0 ? val.toFixed(0) + '%' : "";
                    rowTotal = rowTotal + val;
                });

                if (rowTotal > 0) rows.push(record);
            });

            if (rows.length > 0) {
                let TotalCostCenter = rows[0]?.CostCenter;
                let totalsRecord = {
                    id: group + 'total',
                    Study: "",
                    Organization: "",
                    Department: "",
                    Role:  "",
                    Label: "",
                    CostCenter: TotalCostCenter ? TotalCostCenter : "",
                    Contractor: "",
                    Employee: "",
                    Activity: "Total",
                    isGroupedByRow: true
                };

                _.each(self.state.range.dates, function (month, monthIndex) {
                    let monthName = "_" + Dates.toMonthYearStrShort(month);
                    let dateValues = _.pluck(rows, monthName);
                    let total = 0;
                    total = _.reduce(dateValues, function (memo, num) { 
                        return memo + (parseInt(num) || 0); 
                    }, 0);
                    totalsRecord[monthName] = _.isNaN(total) ? "" : (total > 0 ? total.toFixed(0) + '%' : "");
                });
                rows.push(totalsRecord);
            }
            data = [...data, ...rows];
        });

        return data;
    }

    applyEmployeeFilters(employees) {
        let self = this;
        let filterValues = {
            Name: {},
            Title: {},
            Role: {},
            Label: {},
            Department: {},
            Organization: {},
            Region: {},
            SubRegion: {},
            Email: {},
            EmployeeNumber: {},
            ManagerNumber: {},
            CostCenter: {},
            EmployeeType: {},
            PositionStatus: {},
            Custom1: {},
            Custom2: {},
            Custom3: {},
            Custom4: {},
            Custom5: {},
            employeeSet: {}
        };

        let filterKeys = Object.keys(filterValues);

        let mappedEmployees = _.sortBy(employees, (emp) => {
            emp.NameLf = emp.LastName + ',' + emp.FirstName;
            return emp.NameLf;
        }).map((emp) => {
            let role = EM.roles.byId(emp.RoleId);
            let dept = null;
            if (role) dept = EM.departments.byId(role.DepartmentId);
            let org = null;
            if (dept) org = EM.organizations.byId(dept.OrganizationId);

            let output = Object.assign({}, emp, {
                Name: emp.FirstName + ' ' + emp.LastName,
                Role: role ? role.Name : null,
                DepartmentId: dept ? dept.DepartmentId : null,
                Department: dept ? dept.Name : null,
                OrganizationId: org ? org.OrganizationId : null,
                Organization: org ? org.Name : null,
            });

            filterKeys.forEach((key) => {
                if (output[key]) filterValues[key][output[key]] = true;
            });

            return output;
        });

        filterKeys.forEach((key) => {
            filterValues[key] = Object.keys(filterValues[key]);
        });

        let filteredEmployees = mappedEmployees.filter((employee) => {
            let keys = filterKeys;
            if (keys.length === 0) return true;

            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                if (key === 'employeeSet') {
                    let specifiedValue = self.state.preferences.get(key);
                    if (!specifiedValue || specifiedValue === 'All') continue;
                    if (specifiedValue === 'Active' && !employee.Active) return false;
                    if (specifiedValue === 'Inactive' && employee.Active) return false;
                } else {
                    let acceptedValues = self.state.preferences.get(key);
                    if (!acceptedValues) continue;

                    let empValue = employee[key];
                    if (acceptedValues.indexOf(empValue) === -1) {
                        return false;
                    }
                }
            };

            return true;
        });

        return filteredEmployees;
    }

    getReportData(tableType) {
        // console.log(tableType)
        let self = this;
        if (!self.state.range) return [];

        let data = [];
        let employees = self.state.employees;

        let groupKeys = self.state.groups ? Object.keys(this.state.groups).sort() : [];
        let projectionGroups = {};
        _.each(groupKeys, function (key) {
            projectionGroups = Object.assign({}, projectionGroups, self.state.groups[key]);
        });

        switch (tableType) {
            case 'listing':
                data = self.getListingTypeReportData(employees, projectionGroups);
                break;
            case 'groupedlisting':
                data = self.getGroupedListingTypeReportData(employees, projectionGroups);
                break;
            case 'groupedbyemployee':
                data = self.getEmployeeTypeReportData(employees, projectionGroups);
                break;
            case 'groupbycostcenter':
                data = self.getCostCenterTypeReportData(employees, projectionGroups);
                break;
            default:
                break;
        }

        return data;
    }

    createListingTableColumns() {
        let columns = [
            {
                dataField: 'Study',
                text: EM.t('reports.staffing.columns.study'),
                editable: false
            },          
            {
                dataField: 'Date',
                text: EM.t('reports.staffing.columns.date'),
                editable: false
            },
            {
                dataField: 'Assignment',
                text: EM.t('reports.staffing.columns.assignment'),
                editable: false
            },
            {
                dataField: 'DemandProjection',
                text: EM.t('reports.staffing.columns.demandProjection'),
                editable: false
            }];

        return ColumnMapper(columns);
    }

    createGroupedListingTableColumns(data) {
        let self = this;
        let columns = [
            {
                dataField: 'Study',
                text: EM.t('reports.staffing.columns.study'),
                editable: false,
                width: 200
            },
            {
                dataField: 'Activity',
                text: EM.t('reports.staffing.columns.activity'),
                editable: false,
                width: 200
            },
            {
                dataField: 'Role',
                text: EM.t('reports.staffing.columns.role'),
                editable: false,
                width: 200
            },
            {
                dataField: 'Employee',
                text: EM.t('reports.staffing.columns.employee'),
                editable: false,
                width: 200,
                formatter: (cell, row) => {
                    let emp = cell + (row.Contractor === 'Yes' ? '*' : '');
                    return this.groupByRowFormatter(emp, row);
                }
            },
            {
                dataField: 'Label',
                text: EM.t('reports.staffing.columns.label'),
                editable: false,
                width: 200
            },
        ];

        let dates = _.filter([..._.keys(data)], function (key) { return key.indexOf('_') >= 0; });
        _.each(dates, function (key) {
            columns.push(
                {
                    dataField: key,
                    text: key.split('_')[1],
                    editable: false,
                    formatter: self.groupByRowFormatter,
                    width: 100
                }
            );
        });

        return ColumnMapper(columns);
    }

    createEmployeeTableColumns(data) {
        let self = this;
        let columns = [
            {
                dataField: 'Organization',
                text: EM.t('reports.staffing.columns.organization'),
                editable: false,
                width: 200
            },
            {
                dataField: 'Department',
                text: EM.t('reports.staffing.columns.department'),
                editable: false,
                width: 200
            },
            {
                dataField: 'Role',
                text: EM.t('reports.staffing.columns.role'),
                editable: false,
                width: 200
            },
            {
                dataField: 'CostCenter',
                text: EM.t('reports.staffing.columns.costCenter'),
                editable: false,
                width: 100
            },
            {
                dataField: 'Contractor',
                text: EM.t('reports.staffing.columns.contractor'),
                editable: false,
                width: 100
            },
            {
                dataField: 'Employee',
                text: EM.t('reports.staffing.columns.name'),
                editable: false,
                width: 200,
                formatter: (cell, row) => {
                    let emp = cell + (row.Contractor === 'Yes' ? '*' : '');
                    return emp;
                }
            },
            {
                dataField: 'Study',
                text: EM.t('reports.staffing.columns.study'),
                editable: false,
                width: 200
            },
            {
                dataField: 'Label',
                text: EM.t('reports.staffing.columns.label'),
                editable: false,
                width: 200
            },
            {
                dataField: 'Activity',
                text: EM.t('reports.staffing.columns.activity'),
                editable: false,
                width: 200,
                formatter: (cell, row) => {
                    return this.groupByRowFormatter(cell, row);
                }
            }
        ];

        let dates = _.filter([..._.keys(data)], function (key) { return key.indexOf('_') >= 0; });
        _.each(dates, function (key) {
            columns.push(
                {
                    dataField: key,
                    text: key.split('_')[1],
                    editable: false,
                    formatter: self.groupByRowFormatter,
                    width: 100
                }
            );
        });

        return ColumnMapper(columns);
    }

    createCostCenterTableColumns(data) {
        let self = this;
        let columns = [
            {
                dataField: 'Organization',
                text: EM.t('reports.staffing.columns.organization'),
                editable: false,
                width: 200
            },
            {
                dataField: 'Department',
                text: EM.t('reports.staffing.columns.department'),
                editable: false,
                width: 200
            },
            {
                dataField: 'Role',
                text: EM.t('reports.staffing.columns.role'),
                editable: false,
                width: 200
            },
            {
                dataField: 'Employee',
                text: EM.t('reports.staffing.columns.employee'),
                editable: false,
                width: 200
            },
            {
                dataField: 'CostCenter',
                text: EM.t('reports.staffing.columns.costCenter'),
                editable: false,
                width: 100
            },
            {
                dataField: 'Contractor',
                text: EM.t('reports.staffing.columns.contractor'),
                editable: false,
                width: 100
            },
            {
                dataField: 'Study',
                text: EM.t('reports.staffing.columns.study'),
                editable: false,
                width: 200
            },
            {
                dataField: 'Label',
                text: EM.t('reports.staffing.columns.label'),
                editable: false,
                width: 200
            },
            {
                dataField: 'Activity',
                text: EM.t('reports.staffing.columns.activity'),
                editable: false,
                width: 200,
                formatter: (cell, row) => {
                    return this.groupByRowFormatter(cell, row);
                }
            }
        ];

        let dates = _.filter([..._.keys(data)], function (key) { return key.indexOf('_') >= 0; });
        _.each(dates, function (key) {
            columns.push(
                {
                    dataField: key,
                    text: key.split('_')[1],
                    editable: false,
                    formatter: self.groupByRowFormatter,
                    width: 100
                }
            );
        });

        return ColumnMapper(columns);
    }

    createColumns(tableType, data) {
        switch (tableType) {
            case 'listing':
                return this.createListingTableColumns();
            case 'groupedlisting':
                return this.createGroupedListingTableColumns(data);
            case 'groupedbyemployee':
                return this.createEmployeeTableColumns(data);
            case 'groupbycostcenter':
                return this.createCostCenterTableColumns(data);
            default:
                break;
        }
    }

    onExportXlsx(title, columns, data) {
        new Workbook((wb) => {
            wb.addWorksheetFromColumnsAndData('report', columns, data);
            wb.save('Report');
        });
    }

    render() {
        let readyToRender = EM.allLoaded(EM.configurations, EM.configurationTypeLookup, EM.activities, EM.roles, EM.organizations, EM.departments, EM.employees, EM.assignments, EM.schedules);
        if (!readyToRender) return null;
        if (!this.state.config) return null;

        let tableType = this.state.config.options.tableType ? this.state.config.options.tableType.toLowerCase() : 'groupedlisting';

        let data = this.getReportData(tableType);
        let columns = this.createColumns(tableType, data[0]);
        let title = this.state.config.name + (this.state.selectedSeries ? ' - ' + this.state.selectedSeries.name : '');
        return (
            <div key="contents" className={"page container-fluid "}>
                <ToolkitProvider
                    keyField="id"
                    exportCSV={{
                        fileName: 'Report.csv'
                    }}
                    data={data || []}
                    columns={columns || []}
                >
                    {props => (
                        <div>
                            <PageTitle icon={this.state.config.icon} title={title} bar={true}>
                                {this.state.config ?
                                    <div className="btn-group">
                                        <button className="btn btn-light btn-sm" onClick={() => {
                                            this.setState({ reportDefinitionOpen: true });
                                        }}>
                                            <i className="fas fa-info-circle"></i> {EM.t('reports.publishing.viewDefinition')}
                                        </button>
                                    </div>
                                    : null}
                                {this.state.config.source === 'configuration' && (EM.isMyEntity(this.state.config.storedConfiguration) || EM.isDomainAdmin()) ?
                                    <div className="btn-group">
                                        <button className="btn btn-secondary btn-sm" onClick={() => {
                                            this.setState({ publishModalOpen: true });
                                        }}>
                                            <i className="far fa-edit"></i> {EM.t('reports.publishing.edit')}
                                        </button>
                                    </div>
                                    : null}
                                {EM.isDomainEditor() && this.state.config.source === 'custom' ?
                                    <div className="btn-group">
                                        <button className="btn btn-success btn-sm" onClick={() => {
                                            this.setState({ publishModalOpen: true });
                                        }}>
                                            <i className="fas fa-save"></i> {EM.t('reports.publishing.publish')}
                                        </button>
                                    </div>
                                    : null}
                                <div className="btn-group">
                                    <button className="btn btn-secondary btn-sm" onClick={this.onPrint}>
                                        <i className="fas fa-print"></i> {EM.t('reports.publishing.print')}
                                    </button>
                                </div>
                                <UncontrolledButtonDropdown size="sm">
                                    <DropdownToggle caret>
                                        <i className="fas fa-download"></i>{EM.t('util.table.exportRecords')}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem onClick={() => props.csvProps.onExport()}>CSV</DropdownItem>
                                        <DropdownItem onClick={() => this.onExportXlsx(title, columns, data)}>XLSX</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </PageTitle>
                            <div className={"tabular-report"}>
                                <BootstrapTable
                                    keyField="id"
                                    classes="table table-bordered table-striped table-xs"
                                    {...props.baseProps}
                                />
                                <PublishOptionsDialog
                                    onClose={this.onPublishModalClose}
                                    isOpen={this.state.publishModalOpen}
                                    onPublish={this.onPublish}
                                    configuration={this.state.config.storedConfiguration}
                                    onDelete={this.onDelete}
                                />

                                <ReportDetailsDialog
                                    onClose={() => {
                                        this.setState({ reportDefinitionOpen: false });
                                    }}
                                    isOpen={this.state.reportDefinitionOpen}
                                    configuration={this.state.config.storedConfiguration || this.state.config}
                                    reportOptions={this.state.config.baseConfig.inst}
                                    i18nKeyBase="demand.tools"
                                />

                            </div>
                        </div>
                    )}
                </ToolkitProvider>
            </div>
        );
    }
}
