/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import BudgetsTabs from '../components/BudgetsTabs';
import { Validators } from '../util/EntityValidators';


class AverageWorkHours extends Component {
    constructor(props){
        super(props);
        EM.averageWorkingHours.load();
    }
    render(){
      let data = null;
      let columns = null;
      const entity = EM.averageWorkingHours;
      if(EM.allLoaded(entity)){
        data = entity.get()
        
        columns = [
            {
                dataField: "AverageWorkingHourId",
                text:(entity.columns('Country')).split('.').pop(),
                fromEntity: entity,
                validators: [Validators.required],
            },
            {
                dataField: "HoursAvailable",
                text: "Hours Available",
                validators: [Validators.required],
                filterOptions: {
                  text: true,
              },
            },
            {
              dataField: "MonthlyAvailableHours",
              text: "Monthly Available Hours",
              creatable: false,
              filterOptions: {
                text: true,
            },
          },
          {
              dataField: "Label",
              text: "Label",
              validators: [Validators.required],
              filterOptions: {
                text: true,
            },
          },
        ];
      }   

    return(
        <div key="contents" className="page container-fluid">
             <BudgetsTabs {...this.props}/>
             <EntityTable entity={entity} data={data} columns={columns} className="table-overflow-container tabs"/>
        </div>
    )
  }
}

export default AverageWorkHours;