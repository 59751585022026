/* global EM */
import React, { Component, Suspense } from 'react';
import PageTitle from '../components/PageTitle';
import FileViewerDialog from '../components/FileViewerDialog';
import _ from 'underscore';
import ProjectionOverlay from '../components/ProjectionOverlay';
import { Validators } from '../util/EntityValidators';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import EntityCreator from '../components/EntityTables/EntityCreator';
import EntityAuditTrail from '../components/EntityTables/EntityAuditTrail';
import Dates from '../util/Dates';
import CSV from '../util/CSV';
import Routes from '../app/Routes';
import NoTableData from '../components/EntityTables/NoTableData';
import UserIconAndName from '../components/UserIconAndName';
import DataOutputHelper from '../util/DataOutputHelper';
import ColumnMapper from '../components/ColumnMapper';
import Spinner from '../components/Spinner';
import MetaStack from '../components/MetaStack';
import InputLabel from '../components/InputLabel';
const DiagnosticsPanel = React.lazy(() => import('../components/DiagnosticsPanel'));

export default class Schedules extends Component {
    constructor(props){
        super(props);

        this._isMounted = false;
        
        this.state = {
            fileId: null,
            fileTitle: null,
            fileSubtitle: null,
            scheduleForGeneration: null,
            isGenerating: false,
            createModalOpen: false,
            auditId: null,
            searchTerm: '',
            diagnosticsPanelItem: null       
        };

        EM.schedules.load();
        EM.activities.load();   
        EM.settings.load();  
        EM.users.load(); 
        EM.resourceRequests.load();

        this.onCloseFile = this.onCloseFile.bind(this);
        this.onCloseGenerate = this.onCloseGenerate.bind(this);
        this.onGenerateRequested = this.onGenerateRequested.bind(this);
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    loadFile(row){
        const entity = EM.schedules;      
        entity.loadFile(row.ScheduleId);
        this.setState({ 
            fileId: entity.makeFileName(row.ScheduleId), 
            fileTitle: row.Name,
            fileSubtitle: Dates.isoToDateTime(row.CreatedOn)
        });
    }

    onCloseFile(){
        this.setState({ fileId: null, fileTitle: null, fileSubtitle: null });
    } 
    
    onCloseGenerate(){
        this.setState({ scheduleForGeneration: null });   
    }    

    async onDownloadProjectionsClicked(name, firstProjection){
        DataOutputHelper.exportProjections(name, firstProjection.ProjectionId)
    }

    onRegenerateClicked(event, schedule){
        this.setState({ scheduleForGeneration: schedule });        
    } 
    
    onGenerateRequested(){
        let self = this;
        let schedule = this.state.scheduleForGeneration;
        if (!schedule)return false;

        this.setState({ isGenerating: true });
        EM.projections.regenerate(schedule.ScheduleId, true).then((results) => {
            //After projections are regenerated, reload schedules so they are up-to-date with the latest projections
            EM.schedules.load(true);            

            if (results && results.data){
                let meta = results.data.getMeta();
                if (meta.Count > 0){
                    EM.setStatusMessage(EM.t('schedules.projSuccessMessage', null, [meta.Count, meta.ProcessingTime/1000]), 'subtle');
                }else{
                    EM.setStatusMessage(EM.t('schedules.noProjMessage'), 'subtle warning', 10000);
                }             
            }            

            if (!self._isMounted)return;        
            this.setState({ isGenerating: false });
            self.onCloseGenerate();            
        }); 

        return true;
    }

    onDelete(row) {
        if (!row) return;
        if (window.confirm(EM.t('util.table.deleteConfirmation'))) {
            let ids = [ row['ScheduleId'] ];
            EM.schedules.delete(ids);
        }
    }  
    
    onUpdate(row, prop, value) {
        if (!row || !prop) return;        
        if (row[prop] !== value){
            EM.schedules.update(row, Object.assign({}, row, { [prop]: value }));
        }
    }  

    isSearchMatch(row){
        if (!this.state.searchTerm)return false;
        if (row.Name){
            if (row.Name.toLowerCase().indexOf(this.state.searchTerm) > -1)return true;
        }
        return false;
    }

    render() {
        let data = null;
        let columns = null;
        const entity = EM.schedules;     
        
        let hideFunctions = EM.getActiveDomain().ParentDomainId && !entity.isOverridden(); 

        if (EM.allLoaded(entity, EM.settings, EM.activities, EM.users)){  //Waiting for additional entities required for upload  
            data = _.sortBy(entity.get(), item => {
                let d = item.IsDefault ? 3152536197530 : Dates.fromISO(item.CreatedOn).toMillis();
                return d;
            }).reverse();
            columns = ColumnMapper([{
                dataField: 'Name',
                text: entity.columns('name'),
                validators: [ Validators.required ]
            }, {
                dataField: 'IsDefault',
                text: entity.columns('isDefault'),
                width: 200,  
                asActive: true        
            }, {
                dataField: 'CreatedOn',
                text: entity.columns('createdOn'),
                width: 200,
                isDateReference: true,              
            }]);
        }     

        let ScheduleTransformationToolUrl = EM.getSetting('ScheduleTransformationTool');
        let domain = EM.getActiveDomain();
        let isChildDomain = domain ? (domain.ParentDomainId ? true : false) : false;
        let canRegenerate = isChildDomain ? EM.projections.isOverridden() : true; 
        let canModify = isChildDomain ? EM.schedules.isOverridden() : true;
        let canScenario = isChildDomain ? EM.scenarios.isOverridden() : true; 
        const showBudgets = EM.hasFeature("budgets");

        return (
            <div key="contents" className="page container-fluid">
                <PageTitle title={entity.t('title')} bar={true}>
                    <div className="table-tools">
                        {EM.isDomainEditor() && !hideFunctions ?
                            <div className="btn-group">
                                <button className="btn btn-success btn-sm" title={EM.t('util.table.addRecord')} onClick={() => {
                                    this.setState({ createModalOpen: true });    
                                }}>
                                    <i className="fas fa-plus"></i><span>{EM.t('util.table.addRecord')}</span>
                                </button>
                            </div>
                        : null }
                        <div className="btn-group">
                            <button className="btn btn-secondary btn-sm" title={EM.t('util.table.getTemplate')} onClick={(event) => {
                                CSV.save('schedule-template', [['Work Item', 'Activity', 'Begin', 'End']]);
                            }}>
                                <i className="fas fa-table"></i><span>{EM.t('util.table.getTemplate')}</span>
                            </button>
                            {ScheduleTransformationToolUrl ? 
                                <a className="btn btn-secondary btn-sm" title={entity.t('transformationTool')} href={ScheduleTransformationToolUrl} target="TransformationTool" rel="noopener noreferrer">
                                    <i className="fas fa-external-link-alt"></i><span>{entity.t('transformationTool')}</span>
                                </a>
                            : null }
                        </div>  
                        <label className="search-label">
                            <span className="sr-only">Search this table</span>
                            <input type="text" 
                                className="form-control form-control-sm" 
                                placeholder="Search" 
                                value={this.state.searchTerm}
                                autoComplete="off" 
                                autoCorrect="off" 
                                autoCapitalize="off" 
                                spellCheck="false"
                                onChange={(event) => {
                                    let value = event.target.value;
                                    this.setState({ searchTerm: value ? value.toLowerCase() : '' });
                                }}
                            />                            
                        </label>
                        <button className="btn btn-default" onClick={() => {
                            this.setState({ searchTerm: ''});
                        }}>×</button>
                    </div>
                </PageTitle>
                { data ? 
                    <div className={"card-rows " + (this.state.searchTerm ? 'search-mode ' : '')}>
                        {data.map(row => {
                            let firstProjection = null;
                            let hasProjections = row.Projections.length > 0 ? true : false;
                            if (row.Projections && row.Projections.length > 0){
                                if ((isChildDomain && EM.projections.isOverridden()) || (!isChildDomain)){
                                    firstProjection = row.Projections.find(proj => proj.DomainId === domain.DomainId);
                                }else{
                                    firstProjection = row.Projections[0];
                                }
                            }
                            
                            let projUser = firstProjection ? EM.users.byId(firstProjection.CreatedBy) : null;
                            let isSearchMatch = this.isSearchMatch(row);

                            return (
                                <div className={["card", (row.IsDefault ? 'card-default' : ''), (isSearchMatch?'search-match':'')].join(' ')} key={row.ScheduleId}>
                                    <div className="card-body">
                                        <h5 className="card-title mb-1 clearfix">
                                            {EM.debugMode ?
                                                <span className="meta-reference">{ row.ScheduleId }<br/></span>
                                            : null }     
                                            <InputLabel characterLimit={100} className="title" text={row.Name} altText="[BLANK]" disabled={hideFunctions} onEdited={event => {
                                                if (!event.target.value || !event.target.value.trim()){
                                                    alert(EM.t('util.noBlankWarning'));
                                                    return;
                                                }
                                                this.onUpdate(row, 'Name', event.target.value.trim());
                                            }} />                                                                                     
                                            <div className="btn-group">
                                                <button className="btn btn-sm btn-domain" onClick={() => this.loadFile(row)}>
                                                    <i className="far fa-eye"></i> {EM.t('util.table.view')}
                                                </button>
                                                <UncontrolledButtonDropdown size="sm" className="menu-button">
                                                    <DropdownToggle className="btn-domain">
                                                        <i className="fas fa-caret-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        { canModify && EM.isDomainEditor() ? 
                                                            <DropdownItem disabled={row.IsDefault} onClick={() => {
                                                                this.onUpdate(row, 'IsDefault', !row.IsDefault);
                                                            }}>Make Default</DropdownItem>
                                                        : null }
                                                        {EM.hasFeature('scenarios') && firstProjection && EM.isDomainEditor() && canScenario ?
                                                            <DropdownItem onClick={() => {
                                                                EM.crossPage = row.ScheduleId;
                                                                this.props.history.push(Routes.compose(Routes.client.scenarios, { DomainName: domain.Name}));
                                                            }}>Create a Scenario</DropdownItem>
                                                        : null }
                                                        <DropdownItem divider />
                                                        {canRegenerate ? 
                                                            <DropdownItem onClick={event => this.onRegenerateClicked(event, row)}>
                                                                {firstProjection ? 
                                                                    EM.t('schedules.regenerateProjections')
                                                                : 
                                                                    EM.t('schedules.generateProjections')
                                                                } Projections
                                                            </DropdownItem>
                                                        : null }
                                                        { firstProjection ?
                                                            <DropdownItem onClick={event => this.onDownloadProjectionsClicked(row.Name, firstProjection)}>{EM.t('schedules.downloadProjections')}</DropdownItem>
                                                        : null }
                                                        { EM.isDomainEditor() && EM.debugMode ?
                                                            <DropdownItem onClick={event => {
                                                                this.setState({ diagnosticsPanelItem: row })
                                                            }}>{EM.t('schedules.diagnostics')}</DropdownItem>
                                                        : null }
                                                        { showBudgets ?
                                                            <DropdownItem disabled={!hasProjections}>{EM.t('budgets.calculateBudget')}</DropdownItem>
                                                        : null }
                                                        { showBudgets ?
                                                            <DropdownItem disabled={!hasProjections}>{EM.t('budgets.downloadBudget')}</DropdownItem>
                                                        : null }
                                                        { (firstProjection || canRegenerate) && EM.isDomainEditor() && canModify ? 
                                                            <DropdownItem divider />
                                                        : null }
                                                        { canModify && EM.isDomainAdmin() ?
                                                            <DropdownItem onClick={event => this.setState({ auditId: row.ScheduleId })}>{EM.t('util.table.audit')}</DropdownItem>
                                                        : null }
                                                        { canModify && EM.isDomainEditor() ?
                                                            <DropdownItem disabled={row.IsDefault} className={row.IsDefault?"text-muted":"text-danger"} onClick={event => this.onDelete(row)}>{EM.t('util.table.deleteRecords')}</DropdownItem>
                                                        : null }
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown> 
                                            </div>     
                                        </h5>                                         
                                        <div className="mt-2 card-text clearfix font-sm text-muted">
                                            <InputLabel characterLimit={200} type="textarea" className="description" text={row.Description} altText="No description." disabled={hideFunctions} onEdited={event => {
                                                this.onUpdate(row, 'Description', event.target.value.trim());
                                            }} />                                          
                                            <MetaStack item={row} />
                                        </div>    
                                    </div>
                                    <div className="card-footer">
                                        {firstProjection ?
                                            <div className="meta-stack">
                                                <div>
                                                    <h5>{EM.t('schedules.columns.projections')}</h5>
                                                    <div>{Dates.isoToDateTime(firstProjection.CreatedOn)}</div>
                                                    <UserIconAndName user={projUser} className="font-xs"/>
                                                </div>
                                                <div>
                                                    <h6>&nbsp;</h6>
                                                    <div>{(firstProjection.ProcessingTime / 1000).toFixed(3)} {EM.t('schedules.projectionTimeUnit')}</div>
                                                    <div>{EM.t('schedules.projectionCount')}: {firstProjection.Count > 0 ? firstProjection.Count : 0}</div>
                                                </div>
                                            </div>
                                        : 
                                            <div className="meta-stack">
                                                <div>
                                                    <h5>{EM.t('schedules.columns.projections')}</h5>
                                                    <div>No projections generated.</div>
                                                    <div>&nbsp;</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        {data.length === 0 ?
                            <div className="alert alert-light">
                                <NoTableData data={data} className="text-muted font-bold" />
                            </div>
                        : null }
                    </div>
                : null }
                <FileViewerDialog 
                    files={this.props.files}
                    onClose={this.onCloseFile} 
                    id={this.state.fileId} 
                    title={this.state.fileTitle}
                    subtitle={this.state.fileSubtitle}
                    entity={entity}
                />
                <ProjectionOverlay 
                    schedule={this.state.scheduleForGeneration} 
                    onGenerate={this.onGenerateRequested}
                    onClose={this.onCloseGenerate}
                    isGenerating={this.state.isGenerating}                
                />
                {columns ? 
                    <EntityCreator columns={columns} entity={entity} isOpen={this.state.createModalOpen} key="createModal" 
                        onClose={() => {
                            this.setState({ createModalOpen: false });                        
                        }}
                    />         
                : null }    
                {columns ? 
                    <EntityAuditTrail entityColumns={columns} entity={entity} id={this.state.auditId} onClose={() => {
                        this.setState({ auditId: null });
                    }} />   
                : null } 

                {this.state.diagnosticsPanelItem ? 
                    <Suspense fallback={<Spinner text="Loading..." classes="m-4" />}>                                        
                        <DiagnosticsPanel isOpen={!!this.state.diagnosticsPanelItem} item={this.state.diagnosticsPanelItem} onClose={() => {
                            this.setState({ diagnosticsPanelItem: null });
                        }}/>    
                    </Suspense>                      
                : null }  
            </div>
        );
    }
}
