/* global EM */
import React, { Component } from 'react'
import BudgetsTabs from '../components/BudgetsTabs'
import EntityTable from '../components/EntityTables/EntityTable'
import { Validators } from '../util/EntityValidators';
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

const optionListLocal = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },]
export default class RateCard extends Component {
    constructor(props){
        super(props);
        EM.rateCards.load();
        EM.activities.load();
        EM.roles.load();
        EM.organizations.load();
        EM.countryCurrencies.load();
        EM.departments.load();
        EM.currencyConversions.load();
        EM.currencyCodes.load();
    }
    componentDidMount() {
        if (EM.allLoaded(
            EM.rateCards,
            EM.activities,
            EM.roles,
            EM.organizations,
            EM.departments,
            EM.countryCurrencies,
            EM.currencyConversions,
            EM.currencyCodes
        )) {
            const data = EM.rateCards.get();
            this.setState({ data });
        }
    }
  render() {
    let data = null;
    let columns = null;
    const entity =  EM.rateCards;  
    if (EM.allLoaded(entity, EM.activities, EM.roles, EM.organizations, EM.departments,EM.countryCurrencies,EM.currencyCodes,EM.currencyConversions,)){
        let orgLookup = (cell, row) => {
            let deptId = EM.roles.lookupValue(row.RoleId, 'DepartmentId')
            let orgId = EM.departments.lookupValue(deptId, 'OrganizationId');                        
            return EM.organizations.lookupValue(orgId);     
        };
        let deptLookup = (cell, row) => {
            let deptId = EM.roles.lookupValue(row.RoleId, 'DepartmentId')
            return EM.departments.lookupValue(deptId);                          
        };
        let currencyCodeLookup = (cell,row)=>{
            let countryCurrencyCode = EM.countryCurrencies.lookupValue(row.CountryCurrencyId,'CurrencyCodeId')
            return EM.currencyCodes.lookupValue(countryCurrencyCode,'Code'); 
        }
        let conversionRateToBaseLookup = (cell,row)=>{
            let LocalBooleanValue =  EM.rateCards.lookupValue(row.RateCardId,'IsLocalRate');
            let calculateLocal = LocalBooleanValue == true?'Yes':LocalBooleanValue == false?'No':LocalBooleanValue == 'true' ? 'Yes' : 'No';
            if(calculateLocal == 'No' || calculateLocal == false){
                return 1;
            }
            else if(calculateLocal == 'Yes'){
                let CurrencyCodeId = EM.countryCurrencies.lookupValue(row.CountryCurrencyId,'CurrencyCodeId');
                let getCurrencyConversions = EM.currencyConversions.get();
                return getCurrencyConversions?.filter((x)=> x.CurrencyCodeId === CurrencyCodeId)[0]?.ConversionRate;
            }
        }
        let baseRateValueLookup = (cell , row)=>{
            let currentRate = EM.rateCards.lookupValue(row.RateCardId,'HourlyRate');
            let CurrencyCodeId = EM.countryCurrencies.lookupValue(row.CountryCurrencyId,'CurrencyCodeId');
            let ConversionRate = EM.currencyConversions.get();
            ConversionRate = ConversionRate?.filter((x)=> x.CurrencyCodeId === CurrencyCodeId)[0]?.ConversionRate;
            return (currentRate*ConversionRate).toFixed(2);
        }
        data = entity.get();
        columns = [
            {
                dataField: 'organization',
                text: (entity.columns('Organization')).split('.').pop(),
                isReference: true,
                width: 150,
                formatter: orgLookup,
                sortValue: orgLookup,
                filterValue: orgLookup,
                filterOptions: {
                    dynamicEntity: EM.organizations
                }
            },{
                dataField: 'department',
                text: (entity.columns('Department')).split('.').pop(),
                isReference: true,
                width: 150,
                formatter: deptLookup,
                sortValue: deptLookup,
                filterValue: deptLookup,
                filterOptions: {
                    dynamicEntity: EM.departments
                }
            },{              
                dataField: 'RoleId',
                text:(entity.columns('Role')).split('.').pop(),
                width: 150,
                fromEntity: EM.roles,
                validators: [ Validators.required ]
            },  
            {
                dataField: 'CountryCurrencyId',
                text: (entity.columns('Country')).split('.').pop(),
                width: 100,
                fromEntity: EM.countryCurrencies,
                validators: [ Validators.required ],
            },
            {
                dataField: 'HourlyRate',
                text: (entity.columns('Current Rate')).split('.').pop(),
                width: 150,
                validators: [Validators.required],
                filterOptions: {
                    text: true,
                },
            },
            {
                dataField: 'Year',
                text: (entity.columns('Year')).split('.').pop(),
                width: 100,
                validators: [Validators.required],
                filterOptions: {
                    text: true,
                },
            },
            {
                dataField: 'IsLocalRate',
                text: (entity.columns('Local')).split('.').pop(),
                width: 90,
                editor: {
                    type: Type.SELECT,
                    options: optionListLocal,
                },
                formatter: (cell, row) => {
                    const option = optionListLocal.find((x) => x.value === (row["IsLocalRate"] == "false"? false : row["IsLocalRate"] == "true"?true:row["IsLocalRate"] == false? false:true));
                    return option && option.label == "false"?false : option && option.label =="true" ? true : option ? option.label:'';
                },
            },
            {
                dataField: 'CountryCurrency',
                text: (entity.columns('Country Currency')).split('.').pop(),
                width: 150,
                formatter: currencyCodeLookup,
                sortValue: currencyCodeLookup,
                filterValue: currencyCodeLookup,
                isReference: true,
                filterOptions: {
                    text: true,
                },
            },
            {
                dataField: 'CoversionRatetoBase',
                text: (entity.columns('Conversion Rate')).split('.').pop(),
                width: 150,
                formatter: conversionRateToBaseLookup,
                sortValue: conversionRateToBaseLookup,
                filterValue: conversionRateToBaseLookup,
                filterOptions: {
                    text: true,
                },
            },
            {
                dataField: 'baseRate',
                text: (entity.columns('Base Rate')).split('.').pop(),
                width: 150,
                formatter: baseRateValueLookup,
                sortValue: baseRateValueLookup,
                filterValue: baseRateValueLookup,
                isReference: true,
                filterOptions: {
                    text: true,
                },
            },
         ];
         
    }
    return (
        <>
        <div key="contents" className="page container-fluid">
        <BudgetsTabs {...this.props} />
        <EntityTable
           entity={entity} data={data} columns={columns} className="table-overflow-container" forceMeta={true}
        />
    </div></>
        
    )
  }
}
