/* global EM */
import React, { Component } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from "react-router-dom";
import Routes from "../app/Routes";

export default class BudgetsTabs extends Component {
    render() {
        let domain = this.props.domain ? this.props.domain.Name : '';
        return (
            <Nav tabs>

                <NavItem>
                    <NavLink className="nav-link" to={Routes.compose(Routes.client.rateCard, { DomainName: domain })}>
                        {EM.t('header.rateCard')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className="nav-link" to={Routes.compose(Routes.client.averageWorkHours, { DomainName: domain })}>
                        {EM.t('header.averageWorkingHours')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className="nav-link" to={Routes.compose(Routes.client.countryCurrencyMap, { DomainName: domain })}>
                        {EM.t('header.countryCurrencyMap')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className="nav-link" to={Routes.compose(Routes.client.currencyConversions, { DomainName: domain })}>
                        {EM.t('header.currencyConversions')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className="nav-link" to={Routes.compose(Routes.client.inflationFactor, { DomainName: domain })}>
                        {EM.t('header.inflationFactor')}
                    </NavLink>
                </NavItem>
            </Nav>
        );
    }
}
