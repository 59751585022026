/* global EM */
import React, { Component } from 'react';
import Select from 'react-select';

const customMultiValueLabel = ({ data, innerProps }) => (
    <div className={innerProps.className} title={data.label}>{ data.label }</div>
);

export default class FilterSelection extends Component {
    constructor(props){
        super(props);
        this.state = {
            referenceTables: [],
        }

        this.onChange = this.onChange.bind(this);

        this.optionsDpKey = this.props.label + '-Options';
        EM.clearDpCacheItem(this.optionsDpKey);
    }
     
    static getDerivedStateFromProps(props, state) {
        let isWorkItemFilterEnabled = EM.getSetting("ResourceManager:WorkItems:IsFilterEnabled");
        
        if (EM.isStrictlyDomainSubscriber() && isWorkItemFilterEnabled === 'true') {
            let stateObj = EM.store.getState();
            let userID = stateObj?.account?.User?.UserId;
            let sortedArray = [];
            let filteredArray = [];
            
            stateObj?.referenceTables?.forEach((element) => {
                if (element.Name === 'TeamLead Workitems') {
                    sortedArray = JSON.parse(element.Value);
                }
            });
            
            sortedArray?.forEach((element) => {
                element?.forEach((data, index) => {
                    if (data === userID) {
                        filteredArray.push(element[0]);
                    }
                });
            });
            
            return {
                referenceTables: filteredArray?.length > 0 ? filteredArray : [],
            };
        }
        return null;
    }
    onChange(values){
        let items = values ? values.map(item => { return item.value }) : [];
        this.props.onChange(items);
    }

    render() {
      const sortActivityByOrder = EM.getSetting("SortActivitybyOrder")?.toLowerCase() === "true" ? true : false;
        let options = this.props.options ? EM.fromDpCache(this.optionsDpKey, () => {
            return this.props.options.map((item) => {
                return { value: item, label: item || '<blank>' };
            })
        }) : [];
        let activities = EM.activities.get();        
        let activitiesOptions = activities.sort((a,b) => a.Order - b.Order).map((item) => {          
          return {value: item.Name, label : item.Name || '<blank>'}
        });
        if(EM.isStrictlyDomainSubscriber() && this.state.referenceTables.length > 0 && this.optionsDpKey == 'Work Item-Options'){
            options = options.filter(item => this.state.referenceTables.includes(item.value));
        }
        
        let selected = this.props.selected ? this.props.selected.map((item) => {
            return { value: item, label: item|| '<blank>' };
        }) : [];

        return (
            <div className="form-group">
                <label>{this.props.label}:</label>
                <Select
                    className="multi-select"
                    classNamePrefix="multi-select"
                    isSearchable={true}
                    isMulti={true}
                    value={selected}
                    options={sortActivityByOrder && this.props.label === EM.t("demand.tools.activity") ? activitiesOptions : options}
                    placeholder={EM.t('util.filtering.placeholder')}
                    onChange={this.onChange}
                    isDisabled={this.props.disabled}
                    components={ { MultiValueLabel: customMultiValueLabel } }
                />
            </div>
        );
    }
};